import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { IMaskDirective } from 'angular-imask';
import { reachGoalMetrica } from '@utils/yandex-metrica';
import { Candidate } from '@models/candidate';
import { ToastService } from '@services/toast.service';
import { FeedbackFormsService } from '@technokratos-public/services/feedback-forms.service';
import { SharedPublicFeedbackModule } from '@technokratos-public/components/modals/feedback/shared-public-feedback.module';
import { textMask } from '@utils/masks';
import { AgreementComponent } from '@technokratos-public/components/ui/agreement/agreement.component';
import { FormFieldInfoComponent } from '@ui/form-field-info/form-field-info.component';
import { FormFieldErrorComponent } from '@ui/form-field-error/form-field-error.component';
import { FormFieldTextCounterComponent } from '@ui/form-field-text-counter/form-field-text-counter.component';

@Component({
	standalone: true,
	selector: 'tk-employee-form-modal',
	templateUrl: './employee-form-modal.component.html',
	styleUrls: ['./employee-form-modal.component.sass'],
	providers: [FeedbackFormsService],
	imports: [
		SharedPublicFeedbackModule,
		IMaskDirective,
		AgreementComponent,
		FormFieldInfoComponent,
		FormFieldErrorComponent,
		FormFieldTextCounterComponent,
	],
})
export class EmployeeFormModalComponent {
	@Input() withClose = true;
	@Input() withBack = true;
	@Input() theme: 'black' | 'white' = 'black';
	@Input() sendMetrica = false;

	reachGoalMetrica = reachGoalMetrica();

	feedbackFormsService = inject(FeedbackFormsService);
	fileName: string | null = null;
	file: File | null = null;
	isSubmitting = false;
	protected readonly textMask = textMask;
	private toast = inject(ToastService);
	private fb = inject(FormBuilder);
	private cdr = inject(ChangeDetectorRef);
	protected readonly dialog = inject(Dialog, { optional: true });
	nameMaxLength = 80;
	shotFieldLength = 30;
	longFieldLength = 100;
	form = this.fb.nonNullable.group({
		name: ['', [Validators.required, Validators.maxLength(this.nameMaxLength)]],
		stack: ['', [Validators.required, Validators.maxLength(this.longFieldLength)]],
		experience: ['', [Validators.required, Validators.maxLength(this.shotFieldLength)]],
		phone: ['', [Validators.required, Validators.maxLength(this.longFieldLength)]],
		resume: this.fb.control<null | File>(null, [Validators.required]),
	});

	onInput(event: Event, field: string) {
		this.form.get(field)?.setValue((event.target as HTMLInputElement).value);
	}

	uploadFile(event: Event) {
		const target = event.target as HTMLInputElement;
		const files = target?.files ?? new FileList();

		this.fileName = files[0]?.name;
		if (this.fileName) {
			this.file = files[0];
			this.form.controls.resume.setValue(this.file);
			this.cdr.detectChanges();
		}

		target.value = '';
	}

	deleteFile(e?: Event): void {
		e?.stopPropagation();
		e?.preventDefault();
		this.fileName = null;
		this.form.patchValue({ resume: null });
	}

	submit(formRef: FormGroupDirective) {
		if (this.form.invalid) {
			return;
		}

		if (this.sendMetrica) this.reachGoalMetrica('CAREER_PAGE');

		this.isSubmitting = true;
		const formData = new FormData();
		formData.append('name', this.form.controls.name.value);
		formData.append('stack', this.form.controls.stack.value);
		formData.append('experience', this.form.controls.experience.value);
		formData.append('phone', this.form.controls.phone.value);
		formData.append('resume', this.file!);

		this.feedbackFormsService
			.createCandidateRequest(formData as unknown as Candidate)
			.pipe(
				finalize(() => {
					this.isSubmitting = false;
					this.cdr.markForCheck();
				}),
			)
			.subscribe({
				next: () => {
					formRef.resetForm();
					this.toast.success('Успешно отправлено');
					this.deleteFile();
					this.cdr.markForCheck();
				},
				error: () => this.toast.error('Ошибка'),
				complete: () => this.dialog?.closeAll(),
			});
	}
}
