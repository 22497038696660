<tk-modal-header *ngIf="withClose" />

<tk-arrow-back *ngIf="withBack" />

<form
	[formGroup]="form"
	#formRef="ngForm"
	(ngSubmit)="submit(formRef)"
	autocomplete="off"
	class="form"
	[class.form--white]="theme === 'white'"
>
	<h2 class="form__header">Хочу стать технократом</h2>
	<section class="form__fields">
		<tk-form-field>
			<tk-label>Как нам лучше к вам обращаться?</tk-label>
			<input
				tkInput
				formControlName="name"
				[imask]="textMask"
				placeholder="Дмитрий Иванов"
				type="text"
				class="form__input"
				(input)="onInput($event, 'name')"
			/>
			<tk-form-field-info>
				<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('name')?.errors" />
				<tk-form-field-text-counter
					[currentCount]="form.get('name')?.value?.length || 0"
					[maxCount]="nameMaxLength"
				/>
			</tk-form-field-info>
		</tk-form-field>

		<tk-form-field>
			<tk-label>Какой у вас стек?</tk-label>
			<input
				tkInput
				formControlName="stack"
				type="text"
				placeholder="Python"
				class="form__input"
				(input)="onInput($event, 'stack')"
			/>
			<tk-form-field-info>
				<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('stack')?.errors" />
				<tk-form-field-text-counter
					[currentCount]="form.get('stack')?.value?.length || 0"
					[maxCount]="longFieldLength"
				/>
			</tk-form-field-info>
		</tk-form-field>
		<tk-form-field>
			<tk-label>Какой у вас опыт работы?</tk-label>
			<input
				tkInput
				formControlName="experience"
				type="text"
				placeholder="3 года"
				class="form__input"
				(input)="onInput($event, 'experience')"
			/>
			<tk-form-field-info>
				<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('experience')?.errors" />
				<tk-form-field-text-counter
					[currentCount]="form.get('experience')?.value?.length || 0"
					[maxCount]="shotFieldLength"
				/>
			</tk-form-field-info>
		</tk-form-field>
		<tk-form-field>
			<tk-label>Номер телефона или Telegram</tk-label>
			<input
				tkInput
				formControlName="phone"
				type="text"
				placeholder="Контактные данные"
				class="form__input"
				(input)="onInput($event, 'phone')"
			/>
			<tk-form-field-info>
				<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('phone')?.errors" />
				<tk-form-field-text-counter
					[currentCount]="form.get('phone')?.value?.length || 0"
					[maxCount]="longFieldLength"
				/>
			</tk-form-field-info>
		</tk-form-field>
		<div class="form__file">
			<label class="fileInput">
				<input
					id="file"
					type="file"
					(change)="uploadFile($event)"
					accept="application/pdf, application/msword, text/plain, .docx"
				/>
				<tk-icon class="controls-wrap__paperclip" icon="paperclip" size="24" />
				<span [class.fileUploader--hover]="!fileName" class="fileUploader">
					{{ fileName || 'Прикрепить резюме' }}
				</span>
				<button (click)="deleteFile($event)" *ngIf="fileName" class="controls-wrap__remove">
					<tk-icon icon="cross" size="24" />
				</button>
			</label>
			<span class="message">Допустимые форматы: pdf, txt, doc, docx</span>
		</div>
		<tk-agreement [theme]="theme" />
		<tk-button-group class="tk-button-group">
			<button type="submit">Отправить</button>
		</tk-button-group>
	</section>
</form>
