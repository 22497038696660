import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { LetDirective } from '@directives/src/let.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { TkInputModule } from '@technokratos/components-angular/input';
import { IconComponent } from '@ui/icon/icon.component';
import { TkButtonModule } from '@technokratos/components-angular/button';
import { LoaderComponent } from '@ui/loader/loader.component';
import { ModalHeaderComponent } from '@technokratos-public/components/modals/modal-header/modal-header.component';
import { ArrowBackComponent } from '@technokratos-public/components/modals/arrow-back/arrow-back.component';

const sharedModules = [
	ModalHeaderComponent,
	AsyncPipe,
	CommonModule,
	LetDirective,
	ArrowBackComponent,
	ReactiveFormsModule,
	TkInputModule,
	IconComponent,
	TkButtonModule,
	LoaderComponent,
];

@NgModule({
	imports: sharedModules,
	exports: sharedModules,
})
export class SharedPublicFeedbackModule {}
